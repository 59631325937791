import * as React from "react"

import Layout from "../components/layout"
import PrivacyPolicy from "../components/main/PrivacyPolicy"
import Seo from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <PrivacyPolicy />
  </Layout>
)

export default PrivacyPolicyPage
